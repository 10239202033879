.content-InformationForgot {
  height: 100vh;
  background: -webkit-linear-gradient(
    270deg,
    rgba(114, 28, 166, 0.3229),
    rgba(203, 162, 255, 0)
  );
}
.Asterisk {
  color: red;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0px 8px;
}

/* start info title */
.row-InformationForgot-title {
  margin-bottom: 1%;
}
.col-InformationForgot-title {
  margin-top: 8vh;
}
.text-InformationForgot-top-title {
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
  text-align: center;
}

.text-InformationForgot-title {
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}
/* end info title*/

/* start birth */
.select-InformationForgot-day,
.select-InformationForgot-month,
.select-InformationForgot-years,
.select-InformationForgot-dayshow,
.select-InformationForgot-monthshow,
.select-InformationForgot-yearsshow {
  width: 100%;
}
.select-InformationForgot-dayshow .ant-select-selector,
.select-InformationForgot-monthshow .ant-select-selector,
.select-InformationForgot-yearsshow .ant-select-selector {
  border: 2px solid white !important;
  border-radius: 10em !important;
  height: 5vh !important;
  border-color: #ff0000 !important;
  align-items: center;
}
.select-InformationForgot-day .ant-select-selector:focus,
.select-InformationForgot-month .ant-select-selector:focus,
.select-InformationForgot-years .ant-select-selector:focus,
.select-InformationForgot-day .ant-select-selector:hover,
.select-InformationForgot-month .ant-select-selector:hover,
.select-InformationForgot-years .ant-select-selector:hover,
.select-InformationForgot-dayshow .ant-select-selector:focus,
.select-InformationForgot-monthshow .ant-select-selector:focus,
.select-InformationForgot-yearsshow .ant-select-selector:focus,
.select-InformationForgot-dayshow .ant-select-selector:hover,
.select-InformationForgot-monthshow .ant-select-selector:hover,
.select-InformationForgot-yearsshow .ant-select-selector:hover {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}

.select-InformationForgot-day .ant-select-selector,
.select-InformationForgot-month .ant-select-selector,
.select-InformationForgot-years .ant-select-selector {
  border: 2px solid white !important;
  border-radius: 10em !important;
  height: 5vh !important;
  align-items: center;
}
.row-InformationForgot-checkbox-birth {
  margin-top: 5px;
}
.text-InformationForgot-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}
/* end birth */

/* start passport */
.col-InformationForgot-input .InformationForgot-input-passport {
  border: 2px solid white;
  border-radius: 10em;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.col-InformationForgot-input .InformationForgot-input-passportshow {
  border: 2px solid white;
  border-radius: 10em;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
  border-color: #ff0000 !important;
}
.col-InformationForgot-input .InformationForgot-input-passport:hover,
.col-InformationForgot-input .InformationForgot-input-passport:focus,
.col-InformationForgot-input .InformationForgot-input-passportshow:hover,
.col-InformationForgot-input .InformationForgot-input-passportshow:focus {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}
/* end passport  */

/* start password & confirm*/
.ant-input-affix-wrapper-disabled {
  cursor: unset !important;
}
.col-InformationForgot-input .InformationForgot-input-password,
.col-InformationForgot-input .InformationForgot-input-confirmpassword {
  border: 2px solid white;
  border-radius: 10em !important;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.col-InformationForgot-input .InformationForgot-input-passwordshow,
.col-InformationForgot-input .InformationForgot-input-confirmpasswordshow {
  border: 2px solid white;
  border-radius: 10em !important;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
  border-color: #ff0000 !important;
}
.col-InformationForgot-input .InformationForgot-input-password:hover,
.col-InformationForgot-input .InformationForgot-input-password:focus,
.col-InformationForgot-input .InformationForgot-input-passwordshow:hover,
.col-InformationForgot-input .InformationForgot-input-passwordshow:focus,
.col-InformationForgot-input .InformationForgot-input-confirmpassword:hover,
.col-InformationForgot-input .InformationForgot-input-confirmpassword:focus,
.col-InformationForgot-input .InformationForgot-input-confirmpasswordshow:hover,
.col-InformationForgot-input
  .InformationForgot-input-confirmpasswordshow:focus {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}
/* end password & confirm  */

/* start warning  */
.col-InformationForgot-warning .text-InformationForgot-warning {
  font-weight: bold;
  color: #9f92b6;
  margin: 10px 0px;
}
/* end warning */
/* start button */
.col-InformationForgot-button .button-InformationForgot-confirm {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
}
.col-InformationForgot-button .button-InformationForgot-confirm:hover {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
  border: 1px solid white;
}
.p-button-InformationForgot-confirm {
  margin-bottom: 0;
}
/* end button */

/* start modal Conditions*/
.modalConditions .ant-modal-content {
  border-radius: 1em;
}
.col-title-modal-Conditions {
  text-align: center;
}
.top-title-modal {
  color: #2f0465;
  font-weight: bold;
  margin-bottom: 0;
}
.sub-title-modal {
  color: #2f0465;
}

.col-detail-modal-Conditions .detail-modal {
  color: #6e1ba1;
}

.text-checkbox-modal {
  margin-bottom: 0;
  margin-left: 4px;
  color: #6e4ea6;
  font-weight: bold;
}
.col-button-modal-Conditions {
  text-align: center;
}
.col-button-modal-Conditions .button-modal-Conditions {
  border-radius: 1em;
  background-color: #9b51e0;
  margin-top: 5vh;
}
.button-modal-Conditions .text-button-modal {
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
}
/* end modal Conditions */

/* start modal fail */
.InformationForgot-modalFail .ant-modal-content {
  border-radius: 1em;
}
.col-InformationForgot-modal-logo-fail,
.col-InformationForgot-modal-text-fail {
  text-align: center;
  margin-top: 5vh;
}
.LogoClose-InformationForgot {
  width: 20vh;
}
.text-InformationForgot-modal-fail {
  font-weight: bold;
  color: #927bb9;
}
/* end modal fail */

/* start modal success */
.InformationForgot-modalSuccess .ant-modal-content {
  border-radius: 1em;
}
.col-InformationForgot-modal-logo-success,
.col-InformationForgot-modal-text-success {
  text-align: center;
  margin-top: 5vh;
}
.LogoSuccess-InformationForgot {
  width: 20vh;
}
.text-InformationForgot-modal-success {
  font-weight: bold;
  color: #927bb9;
}
/* end modal success */
/* @media only screen and (max-width: 426px) {
  .select-mar {
    margin-bottom: 1rem;
  }
} */
.select-mar {
  margin-bottom: 1rem;
}
