.content-documentDetail {
  height: 45vh;
  margin: 0;
  background: #2f0465;
  /* background: linear-gradient(
    180.09deg,
    rgba(114, 28, 166, 0.322934) -22.96%,
    rgba(203, 162, 255, 0) 68.14%
  ); */
  /* height: 100vh; */
}
.row-header-documentDetail {
  padding-bottom: 3%;
}
.col-bg-documentDetail {
  /* height: auto; */
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.bg-row-documentDetail {
  width: 100vw;
  padding: 2%;
}
/* start header  */
.col-header-documentDetail {
  background: #2f0465;
  border-radius: 10px 10px 0px 0px;
  display: flex !important;
  align-items: center;
  margin-top: 20px;
}
.col-header-documentDetail .title-header-documentDetail {
  color: #ffffff;
}

/* end header */

/* start Content */
.col-content-documentDetail {
  background: #ffffff;
  padding: 10px;
}
/* start detail */
.p-topic-doucumentDrtail {
  color: #6e1ba1;
}
.col-content-documentDetail-detail-comment .p-topic-doucumentDrtail {
  white-space: pre-line;
}
/* .p-value {
  color: #2f0465;
} */
/* end detail */

/* start QRCode */

.col-content-documentDetail-delete {
  display: flex !important;
  align-items: center;
}
.col-content-documentDetail-qrcode {
  text-align: center;
}
/* end QRCode */

/* end Content */
.QRcode {
  width: 100%;
}
.Dustbin {
  width: 100%;
}

/* start button */
.col-button-documentDetail {
  text-align: center;
}
.col-button-documentDetail .button-documentDetail {
  color: #ffffff;
  background-color: #9b51e0;
  width: 100%;
  border-radius: 4px;
}
.col-button-documentDetail .button-documentDetail-cancel {
  color: #ffffff;
  background-color: #8b8b8b;
  width: 100%;
  border-radius: 4px;
}
.col-button-documentDetail .button-documentDetail-cancel:hover {
  color: #ffffff;
  background-color: red;
  width: 100%;
  border-radius: 4px;
}
/* end button  */
