.content-ForgotPassword {
  height: 100vh;
  background: -webkit-linear-gradient(
    270deg,
    rgba(114, 28, 166, 0.3229),
    rgba(203, 162, 255, 0)
  );
}

.animation-slide-page-regis {
  animation: register 1s ease forwards;
}

@keyframes register {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* start img logo login */
.row-Logo-ForgotPassword {
  margin-bottom: 1%;
}
.row-Logo-ForgotPassword .col-Logo-ForgotPassword {
  text-align: center;
}
.row-Logo-ForgotPassword .col-Logo-ForgotPassword .logo-ForgotPassword-page {
  width: 75%;
}
/* end img logo login */

/* start content ForgotPassword  */
.row-Content-ForgotPassword {
  margin-top: 1%;
}
.col-Content-ForgotPassword {
  text-align: center;
}
.top-text-doc-ForgotPassword {
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0px;
}
.sub-text-doc-ForgotPassword {
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
  font-size: 1rem;
}
.col-button-doc-ForgotPassword {
  text-align: center;
}
.col-button-doc-ForgotPassword .button-doc-ForgotPassword {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
}
.col-button-doc-ForgotPassword .button-doc-ForgotPassword:hover {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
  border: 1px solid white;
}
.button-doc-ForgotPassword .p-button-doc-ForgotPassword {
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
}

/* end content ForgotPassword  */
