/* start modal */
.modalWarning .ant-modal-content {
  border-radius: 1em;
}
.col-modalWarning-text-top,
.col-modalWarning-ImgDetail,
.col-modalWarning-text-sup,
.col-modalWarning-checkbox {
  text-align: center;
}

.ImgDetail-modalWarning {
  width: 50%;
}
.text-modalWarning-modal {
  font-weight: bold;
  /* margin-bottom: 25px; */
  color: #2f0465;
}
.text-modalWarning-modal-detail {
  margin-bottom: 25px;
  color: #2f0465;
}

/* start button */
.col-modalWarning-button {
  text-align: center;
  /* border: 1px solid red; */
  /* width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #9b51e0;
  color: #ffffff;
  border: transparent; */
}

.button-modalWarning {
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #9b51e0 !important;
}

.p-button-modalWarning {
  color: #ffffff;
  margin-bottom: 0;
}

/* end button */

/* start checkBox */
.text-modalWarning-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}

.ant-btn:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ) !important;
  color: #ffffff;
}

/* end CheckBox */

/* end modal */
