.content-foreigner {
  height: 100vh;
  background: -webkit-linear-gradient(
    270deg,
    rgba(114, 28, 166, 0.3229),
    rgba(203, 162, 255, 0)
  );
}

/* start img logo login */
.row-Logo-foreigner {
  margin-bottom: 1%;
}
.row-Logo-foreigner .col-Logo-foreigner {
  text-align: center;
}
.row-Logo-foreigner .col-Logo-foreigner .logo-foreigner-page {
  width: 100%;
}
/* end img logo login */

/* start content foreigner  */
.row-Content-foreigner {
  margin-top: 1%;
  animation: register 1s ease forwards;
  /* border: solid 1px red; */
}
.col-Content-foreigner {
  text-align: center;
}
.top-text-foreigner {
  color: rgba(47, 4, 101, 1);
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0px;
}
.sub-text-foreigner {
  color: rgba(47, 4, 101, 1);
  font-size: 1rem;
  font-weight: bold;
}
.col-button-foreigner {
  text-align: center;
}
.col-button-foreigner .button-foreigner {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
}
.col-button-foreigner .button-foreigner:hover {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
  border: 1px solid white;
}
.button-foreigner .p-button-foreigner {
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
}

/* end content foreigner  */
