.content-login {
  height: 100vh;
  background: -webkit-linear-gradient(
    270deg,
    rgba(114, 28, 166, 0.3229),
    rgba(203, 162, 255, 0)
  );
  animation: animationUp 1s ease forwards;
  /* overflow: scroll; */
}

@keyframes animationUp {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* start img logo login */
.row-Logo-login {
  margin-bottom: 1%;
}
.row-Logo-login .col-Logo-login {
  text-align: center;
}
.row-Logo-login .col-Logo-login .logo-login-page {
  width: 100%;
}
/* end img logo login */

/* start forgot */
.form-forgot-loginpasge .login-form-forgot {
  float: right;
  color: red;
}
/* end forgot */

/*  start input passport and password */
.username .form-input {
  border-radius: 2vh !important;
  height: 40px;
}
.password .form-input {
  border-radius: 2vh !important;
  height: 40px;
}
/* end input passport and password */

/* start button  */
.form-button-loginpage .login-form-button,
.form-button-loginpage .login-form-button:hover {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 4vh;
  height: 55px;
  padding: 0;
  color: #ffffff;
  background-color: #9b51e0;
  border-color: #ecf0f3;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 0.25),
    1.5px 1.5px 3px rgba(119, 104, 129, 0.3),
    inset 3px 3px 4px rgba(255, 255, 255, 0.4);
  /* box-shadow: 15px 15px 25px rgba(151, 167, 195, 0.5); */
}
.form-button-loginpage .login-form-button .text-top {
  font-size: 1.25rem;
}
.form-button-loginpage .login-form-button .text-sup {
  font-size: 1rem;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: #9b51e0 !important;
  color: #fff !important;
  border-color: #9b51e0 !important;
}
/* .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: none !important;
} */
/* end button */

/* @media screen and (max-width: 576px) {
  .row-Logo-login .col-Logo-login .logo-login-page {
    width: 80%;
    height: auto;
  }
} */

/* start modal fail */
.login-modalFail .ant-modal-content {
  border-radius: 1em;
}
.col-login-modal-logo-fail,
.col-login-modal-text-fail {
  text-align: center;
  margin-top: 5vh;
}
.LogoClose-login {
  width: 20vh;
}
.text-login-modal-fail {
  font-weight: bold;
  color: #927bb9;
}
/* end modal fail */

/* start cookie tab  */
.row-tab-cookie {
  /* position: absolute;
  bottom: 0; */
  position: sticky;
  bottom: 0;
}
.tab-cookie {
  background: rgba(215, 214, 214, 0.29);
  width: 100vw;
  padding: 1em;
  /* display: flex !important;
  align-items: center; */
}
.col-btn-cookie .btn-cookie {
  width: 100%;
  background: #b983ff;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 0.25),
    1.5px 1.5px 3px rgba(119, 104, 129, 0.3),
    inset 3px 3px 4px rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}

.col-text-cookie .text-cookie {
  text-align: center;
  font-size: 1rem;
  color: #616d83 !important;
  margin: 0;
}
.hr {
  width: 60vw;
  margin-right: 20vw;
  margin-left: 20vw;
  border: 1px solid #d9d9d9;
  margin-bottom: 1.5rem;
}
.textonly {
  font-size: 20px;
  color: #661997;
}
.textunderline {
  font-size: 14px;
  color: #661997;
  text-decoration: underline #661997;
}
.icon-thaid {
  width: 30px;
  height: 30px;
  margin-right: 4px;
}

.button-main {
  width: 35vw;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 50px;
  border: 1px solid #fff;
  background-color: #fff;
}
.row-main {
  margin-bottom: 1.5rem;
}
.row-second {
  margin-bottom: 0.75rem;
}
.info-dlt {
  width: 100%;
  max-height: 80vh;
  text-align: center;
}
.info-photo-dlt {
  width: 400px;
  height: 630px;
  object-fit: contain;
}
.row-last {
  margin-bottom: 5vh;
}
