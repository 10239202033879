.modal-site {
  background-color: rgba(255, 255, 255, 0.95);
}
.titlemodal-site {
  color: #2f0465;
  font-size: 1.5rem;
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  padding-bottom: 20px;
}
.picture-modal-site {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.boxslide {
  width: 100%;
  /* min-width: 300px; */
  /* height: auto; */
}

.btn-close {
  align-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 5px;
  padding-right: 5px;
}
