.content-history {
  margin: 0;
  background: #2f0465;
  height: 45vh;
  min-width: 45vh;
  min-height: 45vh;
  /* background: linear-gradient(
    180.09deg,
    rgba(114, 28, 166, 0.322934) -22.96%,
    rgba(203, 162, 255, 0) 68.14%
  ); */
  /* height: 100vh; */
}

.container-history-main {
  padding-top: 2%;
}

.container-history-main-bg {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 80vh;
  overflow: auto;
  background-color: #ffffff;
}

.col-content-history {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
/* start header  */
.col-header-history {
  background: #5307b5;
  border-radius: 10px 10px 0px 0px;
  display: flex !important;
  align-items: center;
  margin-top: 20px;
}
.col-header-history .title-header-history {
  color: #ffffff;
}

/* end header */

/* start Content */
.col-content-history {
  background: #ffffff;
  padding: 10px;
}
/* start detail */
.col-content-history-detail-name p,
.col-content-history-detail-passport p {
  margin: 0;
}
.p-topic {
  color: rgba(47, 4, 101, 0.5);
  margin: 0;
}
.p-value {
  color: #2f0465;
}
/* end detail */

/* start QRCode */

.col-content-history-delete {
  display: flex !important;
  align-items: center;
}
.col-content-history-qrcode {
  text-align: center;
}
/* end QRCode */

/* end Content */
.QRcode {
  width: 100% !important;
  height: 100% !important;
}
.Dustbin {
  width: 100%;
}

/* start button */
.col-button-history {
  text-align: center;
}
.col-button-history {
  text-align: center;
}
.col-button-history p {
  color: white;
}
.button-history {
  width: 100%;
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #9b51e0 !important;
}
.col-button-history-cancle {
  width: 100%;
  text-align: center;
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #f96868 !important;
}
.button-history-cancel {
  width: 100%;
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #f96868 !important;
}
.button-history-cancel p {
  color: white;
}

.col-button-history {
  width: 100%;
  text-align: center;
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #9b51e0 !important;
}

.col-button-history-cancel:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.col-button-history:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

/* end button  */

/* start modal */
.history-modalCancelQ .ant-modal-content {
  border-radius: 1em;
}
.col-history-modalCancelQ-text-top,
.col-history-modalCancelQ-ImgDetail {
  text-align: center;
}

.ImgDetail-history-modalCancelQ {
  width: 50%;
}
.text-history-modalCancelQ-modal {
  font-weight: bold;
  margin-bottom: 25px;
  color: #2f0465;
}

/* start button */
.col-history-modalCancelQ-button .button-history-modalCancelQ {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #b983ff;
  color: #ffffff;
  margin-top: 30px;
}
.p-button-history-modalCancelQ {
  margin-bottom: 0;
}
/* end button */

.text-history-modalCancelQ-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}
/* end modal */
.button-mar {
  margin-top: 1rem;
}
