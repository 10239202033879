.modal-public {
  background-color: rgba(255, 255, 255, 0.95);
}
.titlemodal-public {
  color: #2f0465;
  font-size: 1.5rem;
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  padding-bottom: 20px;
}
.picture-modal-public {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  text-align: center;
}
/* .boxslide {
  width: 100%;
  max-width: 300px;
  height: 100%;
} */

.btn-close {
  align-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 5px;
  padding-right: 5px;
}

.slick-dots li button {
  background-color: #c4c4c4 !important;
  margin-top: 40px !important;
  height: 10px !important;
  width: 10px !important;
  border-radius: 50% !important;
  opacity: 1 !important;
}
.slick-dots .slick-active button {
  background-color: #8b3bbc !important;
  margin-top: 40px !important;
}

.ant-carousel .slick-dots-bottom {
  margin-top: 40px !important;
}
.modal-public-component {
  animation: modal-public-component 100ms ease-in forwards;
  transform: scale(0);
}

@keyframes modal-public-component {
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
