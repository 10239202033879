.container-admin {
  margin: 0;
  background: #2f0465;
  height: 45vh;
}

.section-one-CardID {
  width: 100vw;
  padding-top: 2%;
  /* padding: 2rem; */
}

.col-content-CardID {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #ffff;
  padding: 2rem;
}

.content-admin-layout {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
}

.content-admin-table {
  border: 1px solid red;
}

.Header-admin {
  padding: 3%;
}

.content-admin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-admin {
  flex: 0.45;
}

.rows-admin {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.rows-admin-id_card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.column-admin-2 {
  flex: 1;
}

.space {
  margin-right: 1em;
}

.section-2 {
  padding-top: 1%;
}

.section-3 {
  width: 100vw;
  padding-top: 3%;
  overflow-x: hidden;
}

.col-table-admin {
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.flex-start-admin {
  display: flex;
  justify-content: flex-start;
  padding-left: 4%;
}

.title-admin {
  text-align: center;
  font-weight: bold;
  color: #2f0465;
}

.ant-pagination-item-active a {
  color: #2f0465 !important;
}
.ant-pagination-item-active {
  border-color: #2f0465 !important;
}

.ant-pagination-item:hover {
  border-color: #2f0465 !important;
}

.ant-pagination-item a:hover {
  color: #2f0465 !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-color: #2f0465 !important;
  color: #2f0465 !important;
}

.ID_card {
  height: 10vh;
}

.admin-idname {
  height: 5vh;
}

.admin-fname {
  height: 5vh;
}

.admin-lname {
  height: 5vh;
}

.padding-top-id {
  padding-top: 1%;
}

.default-id_card {
  border: solid 1px #d9d9d9;
  font-size: 5rem;
  border-radius: 40px;
}

.default-id_card-failed {
  border: solid 1px red;
  font-size: 5rem;
  border-radius: 40px;
}

.ant-select-arrow {
  color: #2f0465 !important;
}

.red-noti {
  color: red;
}

.default-id_card:hover {
  border: 1px solid #2f0465 !important;
  /* font-size: 5rem;
  border-radius: 40px;
  overflow: hidden; */
}

/* .asdasdas {
  border: solid 1px red;
  height: 10vh;
} */

.info-input-admin {
  border-radius: 40px !important;
  padding-left: 2%;
}

.ID_card[type="text"] {
  font-size: 1.875rem !important;
  padding-left: 6%;
}

.info-input-admin:hover,
.info-input-admin:focus,
.info-input-admin:hover,
.info-input-admin:focus {
  border: 1px solid #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}

.back-btn-admin {
  border-radius: 1em !important;
  background-color: #ffff !important;
}

.position-addmin-center {
  text-align: center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.admin-btn-back-padding {
  padding-top: 2%;
}

.back-btn-admin span {
  color: black;
}

.back-btn-admin:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
  box-shadow: 0 0 0 0 !important;
  border-color: black !important;
}

.checkEmpty-name {
  border-color: red !important;
}

.back-btn-admin:focus {
  border: black ipx solid !important;
  box-shadow: 0 0 0 0 !important;
}

/* .input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  font-size: 50px;
} */

/* .neumorphic-admin {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.neumorphic--pressed-admin {
  box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
    inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5);
} */

.pointor-tag {
  cursor: pointer;
}

@media (max-width: 320px) {
  .rows-admin {
    flex-direction: column;
  }
  .column-admin {
    flex: 0.67;
  }
  .back-btn-admin {
    width: 100%;
  }
  .admin-btn-back-padding {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .rows-admin {
    flex-direction: column;
  }
  .column-admin {
    flex: 0.67;
  }
  .back-btn-admin {
    width: 100%;
  }
  .admin-btn-back-padding {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .rows-admin {
    flex-direction: column;
  }
  .column-admin {
    flex: 0.9;
  }
  .back-btn-admin {
    width: 100%;
  }
  .admin-btn-back-padding {
    width: 100%;
  }
}
