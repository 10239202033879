.content-information {
  height: 100vh;
  background: -webkit-linear-gradient(
    270deg,
    rgba(114, 28, 166, 0.3229),
    rgba(203, 162, 255, 0)
  );
  /* animation: info-regis 1s ease forwards; */
}
@keyframes info-regis {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.Asterisk {
  color: red;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.col-info-input .info-input {
  border: 2px solid white;
  border-radius: 10em;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.col-info-input .info-input:hover,
.col-info-input .info-input:focus {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}
/* start info title */
.row-information-title {
  margin-bottom: 1%;
  /* border: solid 1px red; */
  animation: info-regis 1s ease forwards;
}

.text-title {
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}
/* end info title*/

/* start info prefix */

.select-prefix {
  width: 100%;
}
.select-prefix .ant-select-selector:focus,
.select-prefix .ant-select-selector:hover {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}

.select-prefix .ant-select-selector {
  border: 2px solid white !important;
  border-radius: 10em !important;
  height: 5vh !important;
  align-items: center;
}

/* end info prefix */

/* start info fName&lName */
.col-info-input .info-input-Fname,
.col-info-input .info-input-Lname {
  border: 2px solid white;
  border-radius: 10em;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.col-info-input .info-input-Fname:hover,
.col-info-input .info-input-Fname:focus,
.col-info-input .info-input-Lname:hover,
.col-info-input .info-input-Lname:focus {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}
/* end  info fName  */

/* start birth */

.select-day .ant-select-selector:focus,
.select-month .ant-select-selector:focus,
.select-years .ant-select-selector:focus,
.select-day .ant-select-selector:hover,
.select-month .ant-select-selector:hover,
.select-years .ant-select-selector:hover {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}

.select-day .ant-select-selector,
.select-month .ant-select-selector,
.select-years .ant-select-selector {
  border: 2px solid white !important;
  border-radius: 10em !important;
  height: 5vh !important;
  align-items: center;
}

.text-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}
.row-info-checkbox-birth {
  margin: 10px;
}
/* end birth */

/* start passport & phone  */
.col-info-input .info-input-passport,
.col-info-input .info-input-phone {
  border: 2px solid white;
  border-radius: 10em;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
}

.col-info-input .info-input-passport:hover,
.col-info-input .info-input-passport:focus,
.col-info-input .info-input-phone:hover,
.col-info-input .info-input-phone:focus {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}
/* end passport & phone */

/* start password & confirm*/
.col-info-input .info-input-password,
.col-info-input .info-input-confirmpassword {
  border: 2px solid white;
  border-radius: 10em !important;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.col-info-input .info-input-password:hover,
.col-info-input .info-input-password:focus,
.col-info-input .info-input-confirmpassword:hover,
.col-info-input .info-input-confirmpassword:focus {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}
/* end password & confirm  */

/* start warning  */
.col-information-warning .text-warning {
  font-weight: bold;
  color: #9f92b6;
  margin: 10px 0px;
}
/* end warning */
/* start button */
.col-information-button .button-confirm {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
}
.col-information-button .button-confirm:hover {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
  border: 1px solid white;
}
.p-button-confirm {
  margin-bottom: 0;
}
/* end button */

/* start modal Conditions*/
.modalConditions .ant-modal-content {
  border-radius: 1em;
}
.col-title-modal-Conditions {
  text-align: center;
}
.top-title-modal {
  color: #2f0465;
  font-weight: bold;
  margin-bottom: 0;
}
.sub-title-modal {
  color: #2f0465;
}

.col-detail-modal-Conditions .detail-modal {
  color: #6e1ba1;
}

.text-checkbox-modal {
  margin-bottom: 0;
  margin-left: 4px;
  color: #6e4ea6;
  font-weight: bold;
}
.col-button-modal-Conditions {
  text-align: center;
}
.col-button-modal-Conditions .button-modal-Conditions {
  border-radius: 1em;
  background-color: #9b51e0;
  margin-top: 5vh;
}
.button-modal-Conditions .text-button-modal {
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
}
/* end modal Conditions */

/* start modal fail */
.modalFail .ant-modal-content {
  border-radius: 1em;
}
.col-modal-logo-fail,
.col-modal-text-fail {
  text-align: center;
  margin-top: 5vh;
}
.LogoClose {
  width: 20vh;
}
.text-modal-fail {
  font-weight: bold;
  color: #927bb9;
}
/* end modal fail */

/* start modal success */
.modalSuccess .ant-modal-content {
  border-radius: 1em;
}
.col-modal-logo-success,
.col-modal-text-success {
  text-align: center;
  margin-top: 5vh;
}
.LogoSuccess {
  width: 20vh;
}
.text-modal-success {
  font-weight: bold;
  color: #927bb9;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* end modal success */
