.content-historyCancel {
  margin: 0;
  background: #2f0465;
  height: 45vh;
  /* background: linear-gradient(
    180.09deg,
    rgba(114, 28, 166, 0.322934) -22.96%,
    rgba(203, 162, 255, 0) 68.14%
  );
  height: 100vh; */
}
.container-historyCancel-main {
  width: 100vw;
  padding-top: 2%;
}

.container-historyCancel-main-bg {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 80vh;
  overflow: auto;
  background-color: #ffffff;
}

/* .container-historyCancel {
  background-color: #ffffff;
  width: 50vw;
  height: 80vh;
} */

.div-cancel-q {
  /* float: left; */
  /* border: 1px solid green; */
  /* background-color: #ffffff; */
  /* width: 80vw; */
  /* text-align: center; */
}

.row-header-historyCancel {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  /* width: 70vw; */
}
/* start header  */
.col-header-historyCancel {
  background: #2f0465;
  opacity: 0.6;
  transition: 0.3s;
  border-radius: 10px 10px 0px 0px;
  display: flex !important;
  align-items: center;
  margin-top: 20px;
}
.col-header-historyCancel .title-header-historyCancel {
  color: #ffffff;
  opacity: 0.6;
  transition: 0.3s;
}

/* end header */

/* start Content */
.col-content-historyCancel {
  border: 1px rgba(47, 4, 101, 0.5) solid;
  background: #ffffff;
  padding: 10px;
  opacity: 0.6;
  transition: 0.3s;
}
/* start detail */
.col-content-historyCancel-detail-name p,
.col-content-historyCancel-detail-passport p {
  margin: 0;
}
.p-topic {
  color: rgba(47, 4, 101, 0.5);
  margin: 0;
}
.p-value {
  color: #2f0465;
}
/* end detail */

/* start QRCode */

.col-content-historyCancel-delete {
  display: flex !important;
  align-items: center;
}
.col-content-historyCancel-qrcode {
  text-align: center;
}
/* end QRCode */

/* end Content */
.QRcode {
  width: 100% !important;
  height: 100% !important;
}
.Dustbin {
  width: 100%;
}

/* start text status */
.row-statause-historyCancel .col-status-historyCancel {
  background-color: #e74031;
  border-radius: 2px;
  /* border: 2px solid #9283ac; */
  text-align: center;
  opacity: 0.6;
  transition: 0.3s;
}
.col-status-historyCancel .text-status-historyCancel {
  color: #ffffff;
  margin: 0;
}
/* end text status */
/* start modal */
.historyCancel-modalCancelQ .ant-modal-content {
  border-radius: 1em;
}
.col-historyCancel-modalCancelQ-text-top,
.col-historyCancel-modalCancelQ-ImgDetail {
  text-align: center;
}

.ImgDetail-historyCancel-modalCancelQ {
  width: 50%;
}
.text-historyCancel-modalCancelQ-modal {
  font-weight: bold;
  margin-bottom: 25px;
  color: #2f0465;
}

/* start button */
.col-historyCancel-modalCancelQ-button .button-historyCancel-modalCancelQ {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  opacity: 0.6;
  transition: 0.3s;
  background-color: #b983ff;
  color: #ffffff;
  margin-top: 30px;
}
.p-button-historyCancel-modalCancelQ {
  margin-bottom: 0;
}
/* end button */

.text-historyCancel-modalCancelQ-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}

/* end modal */
/* Loading */
.size-loadding-history-calcel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
