.container-home {
  margin: 0;
  padding: 2.5rem 0 2rem 0;
}

.home-content-step {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 90vh;
}

.step-home-bg {
  padding: 1.6rem 0 1.6rem 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background: #ecf0f3;
  overflow-x: auto;
  height: 90vh;
}

.warning-officer {
  color: #ff0000 !important;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;
}

.dot-warning {
  height: 15px;
  width: 15px;
  margin-top: 18px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #c4c4c4;
}

.step-content-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content-step-page-calendar {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.loading-center-servicetype {
  width: 100%;
  height: 20rem;
  max-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-main-content {
  overflow: hidden;
  min-height: 82vh;
  max-height: 82vh;
  background: white;
  padding: 2rem;
}

.step-home {
  padding: 1.6rem 0 1.6rem 0;
}

/* .bb {
  height: 20vh;
  background-color: #fff;
} */

.Navigation-Steps-Home {
  padding-top: 30px;
}

.bg-home {
  padding-top: 2rem;
}

.size-loadding {
  display: flex;
  width: 100%;
  height: 35vh;
  text-align: center;
  justify-content: center;
  align-items: center;
}
/* ปุ่ม ยกเลิก กับยืนยัน modal คำเตือนทั้งหมด  */
.button-modal-check {
  border: transparent !important;
  border-radius: 20px !important;
  background-color: #9b51e0 !important;
}

.button-modal-check span {
  color: #ffffff;
}

.back-btn {
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #9b51e0 !important;
}

.back-btn span {
  color: #fff;
}

.back-btn:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.img-logo-home-content {
  text-align: center;
  max-width: 100px;
  height: auto;
  object-fit: contain;
}

.steps-action {
  margin: 2rem;
}

.position-btn {
  text-align: center;
}

.title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #2f0465;
}

.container_office {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* text-align: center; */
}

.subtitle {
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  color: #2f0465;
}

.Search {
  width: 22rem;
  padding-bottom: 1.5%;
  border: transparent;
}

.listItem {
  /* border: solid 1px red; */
  width: 25rem;
  min-height: 300px;
  max-height: 300px;
  overflow: auto;
  border-radius: 10px;
}

.listItem-typework {
  /* width: 50vh; */
  width: 23rem;
  min-height: 300px;
  max-height: 300px;
  /* height: 35vh; */
  overflow: auto;
  border-radius: 10px;
}

.listItem_serviceType {
  width: 23rem;
  /* width: 50vh; */
  height: auto;
  max-height: 20rem;
  overflow: auto;
  border-radius: 10px;
}

.listItem-LicenseType {
  width: 23rem;
  /* width: 50vh; */
  /* height: 30vh; */
  height: auto;
  overflow: auto;
  border-radius: 10px;
}

.ant-list-item {
  flex: 1 !important;
  border-radius: 10px;
  background-color: #ecf0f3;
  margin-top: 10px;
  justify-content: flex-start !important;
}

.site {
  font-size: 1rem;
  color: #2f0465;
  margin-left: 5%;
  cursor: pointer;
}

.disable-item {
  background-color: #c4c4c4;
  cursor: not-allowed !important;
}

.disable-item p {
  cursor: not-allowed !important;
  color: #fff;
}

.disable-item:hover {
  cursor: not-allowed !important;
}

.ant-list-split .ant-list-item {
  border: 0 !important;
}

.item:hover {
  background-color: rgb(145, 129, 129);
  cursor: pointer;
}

.Flatlist {
  display: flex;
}

.ant-popover-inner-content {
  display: none;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input:hover {
  border-color: transparent !important;
}

.ant-steps-item-description {
  font-size: 0.625rem;
}

.ant-steps-item-finish .ant-steps-item-icon .anticon {
  /* border: 2px solid green !important; */
  color: green !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border: 2px solid green !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  /* color: green !important; */
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #2f0465 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  border: 2px solid #2f0465 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: green !important;
}

.ant-steps-item-container {
  animation: step-item-home 500ms ease-in forwards;
  transform: scale(0);
}

@keyframes step-item-home {
  0% {
    /* transform: translateX(-100%); */
    transform: scale(0);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    /* transform: translateY(0%); */
    transform: scale(1);
  }
}

.ant-steps-item-description {
  max-width: none !important;
}

@media (max-width: 992px) {
  .ant-steps-item-finish {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .home-content-step {
    height: auto;
  }
  .height-home {
    height: auto;
  }
  .box-home {
    height: auto;
  }
  .home-main-content {
    height: auto;
  }
  .ant-steps-item-finish {
    display: none !important;
  }
  .step-home-bg {
    overflow: hidden;
  }
  .step-home-bg {
    height: auto;
  }
  .title {
    font-size: 1rem;
  }
  .listItem {
    width: 85vw;
  }
  .listItem-typework {
    width: 85vw;
  }

  .listItem_serviceType {
    width: 85vw;
  }

  .listItem-LicenseType {
    width: 85vw;
  }

  .subtitle {
    width: 85vw;
  }

  .site {
    margin-top: 0;
  }
  .Search {
    width: 90%;
  }
}

.ant-spin-container li {
  animation: list-item-serviceType 500ms ease-in;
}

@keyframes list-item-serviceType {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9b51e0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
