/* start modal */
.modalRating .ant-modal-content {
  border-radius: 1em;
}
.col-modalRating-text-top,
.col-modalRating-ImgDetail,
.col-modalRating-text-sup,
.col-modalRating-icon-star,
.col-modalRating-checkbox {
  text-align: center;
}
.col-modalRating-input-comment .input-modalRating-comment {
  border: 2px solid black;
  border-radius: 10em;
  height: 5vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.col-modalRating-input-comment .input-modalRating-comment:hover,
.col-modalRating-input-comment .input-modalRating-comment:focus {
  border-color: #2f0465 !important;
  box-shadow: 0 0 0 0 !important;
}

.ImgDetail-modalRating {
  width: 50%;
}
.text-modalRating-modal {
  font-weight: bold;
  margin-bottom: 0px;
  color: #2f0465;
}
.text-title-comment-modalRating-modal {
  color: #2f0465;
}

.text-modalRating-modal-detail {
  margin-bottom: 25px;
  color: #2f0465;
}

/* start button */
.col-modalRating-button {
  padding-top: 2%;
  text-align: center;
  /* width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #9b51e0;
  color: #ffffff;
  margin-top: 10px; */
}
.button-modalRating {
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #9b51e0 !important;
}
.button-modalRating:hover {
  color: #ffffff !important;
}
.p-button-modalRating {
  margin-bottom: 0;
  color: #ffffff;
}
/* end button */

/* start checkBox */
.text-modalRating-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}

/* end CheckBox */

/* end modal */
