.content-documentDetail {
  background: linear-gradient(
    180.09deg,
    rgba(114, 28, 166, 0.322934) -22.96%,
    rgba(203, 162, 255, 0) 68.14%
  );
  /* height: 100vh; */
}
/* start header  */
.col-header-documentDetail {
  background: #2f0465;
  border-radius: 10px 10px 0px 0px;
  display: flex !important;
  align-items: center;
}
.col-header-documentDetail .title-header-documentDetail {
  margin-top: 0.5em !important;
  color: #ffffff;
}

/* end header */

/* .row-header-documentDetail {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
} */

/* start Content */
.col-content-documentDetail {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  overflow: auto;
  height: 50vh;
  background: #ffffff;
  padding: 10px;
  /* border: solid 1px red; */
}
/* start detail */
.col-content-documentDetail {
}
.p-topic-doucumentDrtail {
  color: #6e1ba1;
}
.col-content-documentDetail-detail-comment .p-topic-doucumentDrtail {
  white-space: pre-line;
}
/* .p-value {
  color: #2f0465;
} */
/* end detail */

/* start QRCode */
.col-content-documentDetail-qrcode {
}
.col-content-documentDetail-delete {
  display: flex !important;
  align-items: center;
}
.col-content-documentDetail-qrcode {
  text-align: center;
}
/* end QRCode */

/* end Content */
.QRcode {
  width: 100%;
}
.Dustbin {
  width: 100%;
}

/* start button */
.col-button-documentDetail {
  text-align: center;
}
.col-button-documentDetail .button-documentDetail {
  color: #ffffff;
  background-color: #9b51e0;
  width: 100%;
  border-radius: 4px;
}
.col-button-documentDetail .button-documentDetail-cancel {
  color: #ffffff;
  background-color: #8b8b8b;
  width: 100%;
  border-radius: 4px;
}
.col-button-documentDetail .button-documentDetail-cancel:hover {
  color: #ffffff;
  width: 100%;
  border-radius: 4px;
}
/* end button  */
