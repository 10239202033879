/* start modal */
.modalSameQ .ant-modal-content {
  border-radius: 1em;
}
.col-modalSameQ-text-title,
.col-modalSameQ-text-sub,
.col-modalSameQ-checkbox,
.col-modalSameQ-text-top-Checkbox,
.col-modalSameQ-ticket {
  text-align: center;
}

.text-modalSameQ-modal-title {
  font-weight: bold;
  margin-bottom: 25px;
  color: #2f0465;
}
.text-modalSameQ-modal-sub {
  font-weight: bold;
  margin-bottom: 0px;
  color: #2f0465;
}
.text-modalSameQ-modal-sub-detail,
.text-modalSameQ-top-Checkbox {
  color: #2f0465;
}
.text-modalSameQ-modal-sub-detail-danger {
  color: red;
}

/* start button */
.col-modalSameQ-button {
  text-align: center;
  /* width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #9b51e0;
  color: #ffffff;
  border: transparent; */
}

.button-modalSameQ {
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #9b51e0 !important;
}

.p-button-modalSameQ {
  color: #ffffff;
  margin-bottom: 0;
}
/* end button */

/* start checkBox */
.text-modalSameQ-checkbox {
  margin-bottom: 10px;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}

/* end CheckBox */

/* end modal */

.col-modalSameQ-ticket {
  /* border: 1px solid red; */
  box-shadow: 5px 5px 5px grey;
  border-radius: 1em;
  margin-bottom: 10px;
}
.col-modalSameQ-ticket-left,
.col-modalSameQ-ticket-right {
  /* box-shadow: 5px 5px 5px grey; */
}
/* start ticket */
.col-modalSameQ-ticket-left {
  border-radius: 1em 0px 0px 1em;
  background: rgba(204, 204, 204, 0.1);
}
.col-modalSameQ-ticket-right {
  border-radius: 0px 1em 1em 0px;
  background: #2f0465;
}
.text-modalSameQ-ticket-st {
  color: #aea3ba;
  margin-bottom: 0;
}
.text-modalSameQ-ticket-nd {
  font-weight: bold;
  margin-bottom: 0;
  text-align: start;
  color: #462177;
}
.text-modalSameQ-ticket-rd {
  text-align: end;
  color: #aea3ba;
  margin-bottom: 0;

  /* width: 200px; */
  /* height: 120px; */
}
/* end ticker */
