.titleprofile {
  font-family: Roboto;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  top: 30px;
  color: #2f0465;
}

.titleAlert {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  color: #2f0465;
  padding-bottom: 30px;
}

.modalprofile .ant-modal-content {
  border-radius: 20px;
}

.modalchangeprofile .ant-modal-content {
  border-radius: 20px;
}
.inputfnameprofile {
  margin-bottom: 10px !important;
  border-radius: 10px !important;
  text-align: right;
}

.inputlnameprofile {
  border-radius: 10px !important;
  text-align: right;
}

.inputnumberprofile {
  border-radius: 10px !important;
  text-align: right;
}

.inputemailprofile {
  border-radius: 10px !important;
  text-align: right;
}

.texts {
  padding-bottom: 10px;
}

.textp {
  margin-top: -7px;
}

.modalsave {
  color: #927bb9;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  line-height: 38px;
  letter-spacing: 0.005em;
}

.dateinput .ant-select-selector {
  border-radius: 10px !important;
  margin-right: 10px !important;
  width: "100%" !important;
  text-align: center;
}
.col-profileChange-birthdate .ant-select {
  width: 100%;
}

.monthinput .ant-select-selector {
  border-radius: 10px !important;
  margin-right: 10px !important;
  width: "100%" !important;
  text-align: center;
}

.yearsinput .ant-select-selector {
  border-radius: 10px !important;
  width: "100%" !important;
  text-align: center;
}

.textModal {
  padding-bottom: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  color: #2f0465;
}

.containermodal {
  background-color: #ffffff;
}
.dbtn {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  background-color: #b983ff;
  color: #2f0465;
  padding-bottom: 30px;
}

.divbe {
  text-align: center;
}

.btn-be {
  border-color: #9b51e0;
  border-radius: 10px !important;
  background-color: #9b51e0 !important;
  color: #ffffff !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 30px;
}

.btn-b:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.row-profile-main {
  width: 100vw;
  padding-top: 1%;
}

.bg-profile-content {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.container-profile {
  margin: 0;
  background: #2f0465;
  height: 45vh;
  /* margin: 0 auto; */
  /* max-width: 40%; */
  /* margin-top: 20px; */
  /* background: linear-gradient(
    180.09deg,
    rgba(114, 28, 166, 0.322934) -22.96%,
    rgba(203, 162, 255, 0) 68.14%
  ); */
}
.container-btnch {
  text-align: center;
  padding-bottom: 16px;
}

.containerimgprofile {
  margin: 0 auto;
}
.testcol {
  /* border: 1px solid red; */
}

.textdata {
  float: left;
}

.logoprofile {
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1%;
}

.btn-close {
  align-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 5px;
  padding-right: 5px;
}

.btn-ch {
  border-color: transparent !important;
  background-color: #9b51e0 !important;
  border-radius: 28px !important;
  color: #ffffff !important;
  /* box-shadow: 10px 8px 21px rgba(151, 167, 195, 0.5) !important; */
}

.btn-ch:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.btn-cancel:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.btn-cancel {
  margin-bottom: 10px;
  border-color: transparent !important;
  background-color: #f96868 !important;
  border-radius: 28px !important;
  color: #ffffff !important;
}

h5 {
  color: dodgerblue !important;
}

.textchangepass {
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.15px;
}

.containerpassword {
  margin-top: 20px;
}
.Breadcrumbchangepassword {
  color: #979b9f;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
}
.info-input-pass-old,
.info-input-pass-new,
.info-input-pass-sub {
  margin-bottom: 10px !important;
  background-color: #fbfbfb;
  border-radius: 30px !important;
  border: 2px solid #d9d9d9 !important;
}

.info-input-pass-oldshow,
.info-input-pass-newshow,
.info-input-pass-subshow {
  background-color: #fbfbfb;
  border-radius: 30px !important;
  /* border: 2px solid red !important; */
}

.ant-input-affix-wrapper-disabled {
  cursor: unset !important;
}

.containerbtnpass {
  margin-top: 30px;
  padding-bottom: 3%;
}

.btnupdatepass:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.btnupdatepass {
  border-color: transparent !important;
  background-color: #9b51e0 !important;
  border-radius: 28px !important;
  color: #ffffff !important;
  /* font-family: Yu Gothic UI; */
  font-style: normal !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.01em !important;
  width: 100%;
}

.iconcheckmark {
  align-items: center !important;
  margin-top: 50px;
  margin-bottom: 20px;
}

.subtextpass {
  font-family: Sukhumvit Set;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 22px;
  letter-spacing: 0.005em;
  margin-top: 10px;
  color: #9283ac !important;
}
.secontext {
  font-family: Sukhumvit Set;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.005em;
  color: #927bb9;
  mix-blend-mode: normal;
}

.profile-input-phoneshow {
  border-radius: 10px !important;
  text-align: right;
  border: 1px solid #ff0000 !important;
}

.profile-input-phone {
  border-radius: 10px !important;
  text-align: right;
}

.col-profile-phone-email {
  word-wrap: break-word;
}

@media (max-width: 1200px) {
  .col-profileChange-birthdate .ant-select {
    margin: 4px 0px;
  }
}
