.position-Modal {
  float: left;
  border-color: #9b51e0 !important;
  background-color: #9b51e0 !important;
  color: #fff !important;
}

.position-Modal:hover {
  border-color: #9b51e0 !important;
  background-color: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
  color: #fff !important;
}

.ant-modal-header {
  background-color: #f96868 !important;
}
.print .ant-modal-header {
  background-color: #68f987 !important;
}

.close-modal-Queue {
  border-color: red !important;
  border: solid 1px "red" !important;
}

.close-modal-Queue:hover {
  border-color: red !important;
  border: solid 1px "red" !important;
}
