.container_calendar {
  display: flex;
  justify-content: space-evenly;
  overflow: hidden;
  /* border: red solid 1px; */
}

.Calendar {
  flex: 0.9;
  /* width: 45%; */
  /* float: left; */
  padding-left: 3%;
  padding-right: 3%;
  /* border-radius: 10px;
  border: solid 3px #2f0465; */
}

.RoundOpen {
  flex: 0.8;
  /* float: right; */
  padding: 0 1rem 0 1rem;
}

.rows {
  display: flex;
  padding: 1%;
}

.Header_calendar {
  justify-content: space-around;
}

.Header span {
  font-size: 0.875rem;
  color: #28cd1a;
}

.day {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.position_day {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.position_day:hover {
  cursor: pointer;
  background-color: #9f7eff;
}

.text_day {
  border: solid 3px #5307b5;
}

.table_calendar {
  /* width: 100px;
  height: 100px; */
}

.bg_calendar {
  background-image: linear-gradient(
    103.49deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
}

.day span {
  font-size: 0.875rem;
  font-weight: bold;
}

.HeaderCalendar {
  display: flex;
  /* justify-content: space-between; */
  padding-top: 2%;
  padding-bottom: 2%;
}

.date {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding-left: 5%;
  font-weight: bold;
  font-size: 1.25rem;
  color: #5307b5;
  /* background: -webkit-linear-gradient(
    180deg,
    #5307b5 0%,
    rgba(7, 55, 181, 0.26) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.flex-row {
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
}

.flex-row_2 {
  display: flex;
  justify-content: flex-start;
  border: solid 1px red;
  flex-direction: row;
}

.Header {
  height: 40px;
  border-radius: 5px;
  background-color: white;
}

.Header span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}

.advice {
  padding: 1rem 0 1rem 0;
  display: flex;
  justify-content: space-evenly;
  /* border: 1px solid red; */
}

.column {
  display: flex;
  flex-direction: column;
  width: 30vh;
}

.one {
  display: flex;
  justify-content: flex-start;
  width: 50vh;
}

.color_txt1 {
  /* font-size: small; */
  color: #bbb;
}
.color_txt2 {
  /* font-size: small; */
  color: #25862f;
}
.color_txt3 {
  /* font-size: small; */
  color: black;
}
.color_txt4 {
  /* font-size: small; */
  color: red;
}

.dot1 {
  height: 2vh;
  width: 2vh;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  align-self: center;
  margin-right: 5%;
}

.dot2 {
  height: 2vh;
  width: 2vh;
  background-color: #25862f;
  border-radius: 50%;
  display: inline-block;
  align-self: center;
  margin-right: 5%;
}

.dot3 {
  height: 2vh;
  width: 2vh;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  align-self: center;
  margin-right: 5%;
}

.dot4 {
  height: 2vh;
  width: 2vh;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  align-self: center;
  margin-right: 5%;
}

.Headder_round {
  display: flex;
  justify-content: space-evenly;
  background-color: white;
}

.choose_day {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0 0 10px 10px;
  height: 10%;
  background-color: #2f0465;
}

.choose_day span {
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.header_office {
  flex: 1;
  padding-left: 3%;
}

.header_office span {
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: bold;
  color: #2f0465;
  margin-top: 2%;
}

.header_office p {
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
  font-size: 0.813rem;
  color: #2f0465;
}

.footer-calendar-roundItem {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer_roundTime {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.box-calendar-footer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1.5rem;
}

.size-box-calendar {
  flex: 1;
}

.box_time {
  margin: 0.6rem 0 0.6rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  -webkit-box-shadow: 0 8px 6px -6px #606060;
  -moz-box-shadow: 0 8px 6px -6px #606060;
  box-shadow: 0 8px 6px -6px #606060;
}

.icon {
  font-size: 1.563rem;
  font-weight: bold;
  color: #2f0465;
}

.full {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.free {
  font-size: 1rem;
  font-weight: bold;
  color: #28cd1a;
}

.bg_full {
  background-color: #cfcfcf;
}

.bg_free {
  background-color: #fff;
}

.pointer_full {
  cursor: not-allowed;
}

.pointer_free {
  cursor: pointer;
}

@media (max-width: 768px) {
  .container_calendar {
    flex-direction: column;
  }
  /* .Calendar {
    display: block;
  } */
  .advice {
    text-align: center;
    flex-direction: column;
  }
  .Headder_round {
    flex-direction: column;
  }
  .advice {
    display: flex;
    align-items: center;
  }
  .box {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .RoundOpen {
    padding-top: 5%;
  }
}

@media (max-width: 767px) {
  .box-calendar-footer {
    padding: 0;
  }
  .box_time {
    width: 7rem;
  }
}

/* @media (max-width: 1024px) {
  .color_txt1 {
    font-size: 0.813rem;
    color: #bbb;
  }
  .color_txt2 {
    font-size: 0.813rem;
    color: #25862f;
  }
  .color_txt3 {
    font-size: 0.813rem;
    color: black;
  }
  .color_txt4 {
    font-size: 0.813rem;
    color: red;
  }
} */

@media (max-width: 320px) {
  .table_calendar {
    padding-left: 0;
    padding-right: 0;
  }
  .box {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
