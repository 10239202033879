.information_title {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  /* border: solid 1px black; */
}

.information_btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* border: 2px solid red; */
}

.option {
  font-size: 0.813rem !important;
  font-weight: bold !important;
  color: #2f0465 !important;
}

.ant-select-selection-placeholder {
  color: red;
}

.information_title p {
  font-size: 1rem;
  color: #2f0465;
}

.height-info-component {
  max-height: 50vh;
  overflow: auto;
}

.select {
  background-color: transparent;
  border-radius: 25px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.neumorphic {
  /* box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.5); */
  font-size: 5rem;
  border-radius: 10px;
  overflow: hidden;
  padding: 1.3rem;
  border: solid 2px #c5c9db !important;
}

.neumorphic--pressed {
  /* box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
    inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5); */
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 0.813rem;
  font-weight: bold;
  color: #2f0465;
}

.info_title {
  font-weight: bold;
  color: #2f0465;
}

.checkValue {
  border: solid 2px red !important;
}

.defaultcheck {
  border: solid 2px red;
  border: solid 2px transparent;
}

/* .ant-checkbox {
  margin: 0;
  border-radius: 3px !important;
  border: solid 1px #2f0465 !important;
  /* box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2),
    -12px -12px 24px 0 rgba(255, 255, 255, 0.5) !important; */
/* } */

.ant-checkbox-inner {
  border: 1px solid #2f0465 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2f0465 !important;
  border-color: #2f0465 !important;
}

.steps-action-info-home {
  text-align: center;
  padding-top: 2%;
}

/* .aaaaa {
  border: solid 1px red;
} */
