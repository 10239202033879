@import "~antd/dist/antd.css";

/* .a {
  border: solid 2px red;
} */

.menu-item-size {
  max-width: 500px;
  min-width: 500px;
}

.menuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  overflow: hidden;
  padding: 0 2rem;
  background-color: #fff;
}

.list-menu-nav {
  min-width: calc(100%);
}

.list-menu-nav-admin {
  display: block;
  min-width: calc(15vw);
}

.container-main-page {
  height: 50vh;
  background: #2f0465;
}

.title-header-nav {
  width: calc(30vw - 80px);
  min-height: 8vh;
  display: flex;
  align-items: center;
}

.title-header-nav span {
  font-size: 1rem;
}

.logo {
  float: left;
}

.logo-nav {
  width: 5vw;
  height: 3rem;
}

.menuCon {
  float: right;
}

.leftMenu {
  float: right;
}

.menuCon .ant-menu-item {
  padding: 10px 15px;
}
.ant-menu-horizontal {
  /* height: 1rem !important; */
  /* border: solid 1px red; */
  /* padding: 0.15rem 0 0.15rem 0; */
  line-height: 3.5rem !important;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  display: none;
  background: none;
  margin-right: 8px;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #9b51e0 !important;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

.ant-menu-title-content {
  /* font-size: 1rem; */
  font-size: 1rem;
}

.ant-menu-title-content:hover {
  color: #9b51e0 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  background-color: #9b51e0 !important;
  border: 2px solid #9b51e0 !important;
}

.ant-menu-item-selected {
  color: #9b51e0 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important; */
  background-color: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light
  .ant-menu-submenu-title:hover
  .ant-menu-horizontal
  > .ant-menu-item-selected
  a {
  color: #9b51e0 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #9b51e0 !important;
}

/* .ant-btn-primary {
  border-color: #9b51e0 !important;
} */

.barsBtn {
  background: #9b51e0 !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  background: #9b51e0 !important;
  color: #9b51e0 !important;
}

@media (max-width: 768px) {
  .title-header-nav {
    display: none;
  }
  /* .menu-item-size {
    max-width: 400px;
    min-width: 400px;
    border: solid 1px red;
  } */
  .barsMenu {
    display: inline-block;
    border-color: #9b51e0 !important;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo-nav {
    width: 10vw;
    height: 3rem;
  }
}
