.content-info {
  background: linear-gradient(
    180.09deg,
    rgba(114, 28, 166, 0.322934) -22.96%,
    rgba(203, 162, 255, 0) 68.14%
  );
  /* height: 100vh; */
}
/* .row-header-info {
  border: solid 5px red;
} */
/* start header  */
.col-header-info {
  border-radius: 10px 10px 0px 0px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.col-header-info .title-header-info {
  /* color: #ffffff; */
  margin-top: 0.5em !important;
  color: #2f0465;
}

/* .aaaaa {
  border: solid 1px red;
} */

/* end header */

/* start Content */
.col-content-info {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  background: #ffffff;
  padding: 10px;
}
/* start detail */
.col-content-info-detail-name p,
.col-content-info-detail-passport p {
  margin: 0;
}
.p-topic {
  color: rgba(47, 4, 101, 0.5);
  margin: 0;
}
.p-value {
  color: #2f0465;
}
/* end detail */

/* start QRCode */
.col-content-info-qrcode {
}
.col-content-info-delete {
  display: flex !important;
  align-items: center;
}
.col-content-info-qrcode {
  text-align: center;
}
/* end QRCode */

/* end Content */
.QRcode {
  width: 100% !important;
  height: 100% !important;
  /* height: 1rem !important; */
}
.Dustbin {
  width: 100%;
}

/* start button */
.col-button-info {
  text-align: center;
}
.button-info {
  border: transparent !important;
  border-radius: 4px !important;
  background-color: #9b51e0 !important;
}

.col-button-info {
  padding-top: 2%;
}
.button-info span {
  color: #ffffff;
}
.col-button-info .button-info-cancel {
  color: #ffffff;
  background-color: #8b8b8b;
  width: 100%;
  border-radius: 4px;
}
.col-button-info .button-info-cancel:hover {
  color: #ffffff;
  background-color: red;
  width: 100%;
  border-radius: 4px;
}
/* end button  */

/* start modal */
.info-modalCancelQ .ant-modal-content {
  border-radius: 1em;
}
.col-info-modalCancelQ-text-top,
.col-info-modalCancelQ-ImgDetail {
  text-align: center;
}

.ImgDetail-info-modalCancelQ {
  width: 50%;
}
.text-info-modalCancelQ-modal {
  font-weight: bold;
  margin-bottom: 25px;
  color: #2f0465;
}

/* start button */
.col-info-modalCancelQ-button .button-info-modalCancelQ {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #b983ff;
  color: #ffffff;
  margin-top: 30px;
}
.p-button-info-modalCancelQ {
  margin-bottom: 0;
}
/* end button */

.text-info-modalCancelQ-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}

.button-info:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ) !important;
  color: black;
  /* color: #ffffff; */
}

@media (max-width: 320px) {
  .margin-l-320px {
    margin-right: 2rem;
  }
}

/* end modal */
