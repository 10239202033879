.time_round-one {
  /* flex: 1; */
  text-align: center;
  padding-top: 10%;
}

.time_round-two {
  /* flex: 1; */
  text-align: center;
}

.time_round-one,
.time_round-two p {
  font-size: 1rem;
  color: #2f0465;
  margin-bottom: 0;
}

.data_title {
  display: flex !important;
  justify-content: flex-end !important;
}

.data_title p {
  font-size: 1rem;
  color: #2f0465;
}

.data_detail {
  display: flex !important;
  justify-content: flex-start !important;
}

.data_detail p {
  font-size: 1rem;
  color: #2f0465;
}

.size {
  flex: 1;
}
.contain_cf {
  padding-top: 2rem;
}

.text_cicle {
  font-size: 1rem;
  color: #fff;
}

.container-cf-Q {
  align-self: center !important;
}

/* .a {
  border: solid 1px red;
} */

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background: #d9d9d9 !important;
}

.steps-action-cf {
  text-align: center;
}

.footer-text-cicle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .swal2-title {
  font-size: 1rem !important;
} */

@media (max-width: 320px) {
  .time_round-two {
    width: 30vh;
  }
  .time_round-two {
    padding-bottom: 3%;
  }
  .data_detail p {
    font-size: 0.813rem;
  }
  .data_title p {
    font-size: 0.813rem;
  }
  .time_round p {
    font-size: 0.813rem;
    color: #2f0465;
    margin-bottom: 0;
  }
  .text_cicle {
    font-size: 0.813rem;
  }
}

@media (max-width: 1200px) {
  .home-content-step {
    height: auto;
  }
  .step-home-bg {
    height: auto;
  }
}
