.title-ticket-queue {
  margin-top: 16px;
}

.logodlt {
  width: 100%;
}

.headticket {
  font-size: 1.25rem !important;
  margin-bottom: 10px;
  font-weight: bold;
  padding-top: 3%;
}

.titleticket {
  font-size: 1rem;
  font-weight: bold;
}

.qrcode-ticket-admin {
  margin-top: 16px;
}

.QRcode-modal-queue-admin {
  height: 100%;
}
.text-top {
  font-weight: bold;
  font-size: 1rem;
}
.text-sub {
  font-weight: normal;
  color: #76838f;
}

.content-ticket {
  background-color: #f1f4f5;
}
.col-ticket {
  background: #fff;
}
.dividerstly {
  margin: 12px 0 !important;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  header,
  footer,
  aside,
  form,
  … {
    display: none;
  }
  html,
  body {
    width: 21cm !important;
    height: 29.7cm !important;
    margin: 0;
  }
  .page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 1.5cm;
    margin: 0cm !important;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  }
  .dividerstly {
    margin: 12px 0 !important;
  }
}
