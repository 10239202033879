@font-face {
  font-family: SukhumvitSet-Bold;
  src: url("./fonts/SukhumvitSet-Bold.ttf");
}

body {
  margin: 0;
  padding: 0;
  /* height: 100vh;
  width: 100vw; */
}

* {
  font-family: SukhumvitSet-Bold;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* @media (min-height: 700px) {
  body {
    min-height: 100vh;
    min-width: 100vw;
  }
} */
