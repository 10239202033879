.modal-component .ant-modal-content {
  border-radius: 1em;
}

.modal-component {
  /* margin-right: 10%;
  padding-right: 10%; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.col-modal-component-text-top,
.col-modal-component-ImgDetail {
  text-align: center;
  /* border: solid red 1px; */
}

.ImgDetail-modal-component {
  width: 50%;
}
.text-modal-component-modal {
  /* font-weight: bold; */
  margin-bottom: 25px;
  color: #2f0465;
}

.text-modal-component-modal-alert {
  color: red;
}

/* start button */
.col-modal-component-button {
  /* border: solid 1px red; */
  text-align: center;
  /* width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #9b51e0;
  color: #ffffff;
  margin-top: 30px;
  border: transparent; */
}

.button-modal-component {
  border: transparent !important;
  border-radius: 1em !important;
  background-color: #9b51e0 !important;
}

.p-button-modal-component {
  color: #ffffff;
  margin-bottom: 0;
}
/* end button */

.text-modal-component-checkbox {
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(47, 4, 101, 1);
  font-weight: bold;
}

.ant-btn:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ) !important;
}
