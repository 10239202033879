.content-register {
  height: 100vh;
  background: -webkit-linear-gradient(
    270deg,
    rgba(114, 28, 166, 0.3229),
    rgba(203, 162, 255, 0)
  );
}

/* start img logo login */
.row-Logo-register {
  margin-bottom: 1%;
}
.row-Logo-register .col-Logo-register {
  text-align: center;
}
.row-Logo-register .col-Logo-register .logo-register-page {
  width: 100%;
}
/* end img logo login */

/* start content register  */
.row-Content-register {
  margin-top: 1%;
  animation: register 1s ease forwards;
}

@keyframes register {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.col-Content-register {
  text-align: center;
}
.top-text-doc {
  color: rgba(47, 4, 101, 1);
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0px;
}
.sub-text-doc {
  color: rgba(47, 4, 101, 1);
  font-size: 1rem;
  font-weight: bold;
}
.col-button-doc {
  text-align: center;
}
.col-button-doc .button-doc:hover {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
  border: 1px solid #ffffff;
}
.col-button-doc .button-doc {
  width: 100%;
  height: 5vh;
  border-radius: 20px;
  background-color: #ecf0f3;
  color: rgba(47, 4, 101, 1);
  margin-bottom: 30px;
}
.button-doc .p-button-doc {
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
}

/* end content register  */
